import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React from 'react';
import Layout from '../components/layout/Layout';

const content = {
	default: {
		title: 'Köszönjük üzenetedet!',
		text: 'További információkat az általunk küldött e-mailben találsz, nézd meg a postafiókodat!',
	},
	changePassword: {
		title: 'Sikeres jelszó változtatás!',
		text: 'Lépj be az applikációdba, és foglalj magadnak munkaidőt saját döntéseid szerint.',
	},
	verifyEmail: {
		title: 'Sikeres e-mail megerősítés!',
		text: 'Lépj be az applikációdba, és foglalj magadnak munkaidőt saját döntéseid szerint.',
	},
	pickupPoint: {
		title: 'Várunk a ViddL csomagponton!',
		text: 'Csomagodat 5 munkanapig átveheted ügyfélszolgálati időben (Hétfő-Péntek 09:00-17:00) a ViddL irodában, címünk 1054 Budapest, Széchenyi utca 1. Ha a mai napon szeretnél bejönni érte, akkor egyeztess ügyfélszolgálatunkkal a +36 70 758 2536 számon!',
	},
	flexDel: {
		title:
			'Módosítási kérésedet rögzítettük, változtatási igényed feldolgozása folyamatban van. További információért kérjük, keresd ügyfélszolgálatunkat az alábbi elérhetőségek egyikén!',
	},
};

const contacts = [
	{
		city: 'Budapest',
		tel: '+36 70 758 2536',
		email: 'budapest@viddl.hu',
	},
	// {
	// 	city: 'Győr',
	// 	tel: '+36 30 110 63 38',
	// 	email: 'gyor@viddl.hu',
	// },
	// {
	// 	city: 'Debrecen',
	// 	tel: '+36 70 738 6262',
	// 	email: 'debrecen@viddl.hu',
	// },
	// {
	// 	city: 'Szeged',
	// 	tel: '+36 30 578 9486',
	// 	email: 'szeged@viddl.hu',
	// },
	// {
	// 	city: 'Kecskemét',
	// 	tel: '+36 20 280 93 81',
	// 	email: 'kecskemet@viddl.hu',
	// },
	// {
	// 	city: 'Miskolc',
	// 	tel: '+36 70 328 0983',
	// 	email: 'miskolc@viddl.hu',
	// },
	// {
	// 	city: 'Székesfehérvár',
	// 	tel: '+36 70 534 9507',
	// 	email: 'szekesfehervar@viddl.hu',
	// },
	// {
	// 	city: 'Nyíregyháza',
	// 	tel: '+36 70 758 2535',
	// 	email: 'nyiregyhaza@viddl.hu',
	// },
];

const SuccessPage = ({ data, location }) => {
	const type = location.state?.type;
	const text = content[type] ?? content.default;

	return (
		<Layout footerImg={data.logo_footer} footerEitImg={data.logo_eit_footer}>
			<section className="pb-0 bg-white">
				<div className="container">
					{type === 'flexDel' ? (
						<div className=" text-center">
							<h4>{text.title}</h4>
							<div className="container-fluid p-4">
								<div className="row">
									{contacts.map((contact) => (
										<div
											key={contact.city}
											className="col-xs-12 col-md-4 col-sm-3 p-2"
										>
											<p className="font-weight-bold">{contact.city}</p>
											<p>{contact.tel}</p>
											<p>{contact.email}</p>
										</div>
									))}
								</div>
							</div>
						</div>
					) : (
						<div className="row">
							<div className="col-12 text-center">
								<h2 style={{ maxWidth: 'unset' }}>{text.title}</h2>
								<p className="mt-3 mb-5">{text.text}</p>
								<Img
									fluid={data.feedback.childImageSharp.fluid}
									alt={text.title}
									style={{
										width: '70%',
										maxWidth: 500,
										marginLeft: 'auto',
										marginRight: 'auto',
									}}
								/>
								<Link
									to="/"
									className="btn btn-lg btn-primary my-5 py-2"
									aria-label="Rendben"
								>
									Rendben
								</Link>
							</div>
						</div>
					)}
				</div>
			</section>
		</Layout>
	);
};

SuccessPage.propTypes = {
	location: PropTypes.shape({
		state: PropTypes.shape({
			type: PropTypes.oneOf(Object.keys(content)),
		}),
	}).isRequired,
};

export const query = graphql`
	{
		logo_footer: file(relativePath: { eq: "logo_footer.png" }) {
			childImageSharp {
				fixed(width: 92, quality: 100) {
					...GatsbyImageSharpFixed_withWebp_tracedSVG
				}
			}
		}
		logo_eit_footer: file(relativePath: { eq: "eit.png" }) {
			childImageSharp {
				fixed(width: 200, quality: 100) {
					...GatsbyImageSharpFixed_withWebp_tracedSVG
				}
			}
		}
		feedback: file(relativePath: { eq: "feedback_illu.png" }) {
			childImageSharp {
				fluid(maxWidth: 500, quality: 100) {
					...GatsbyImageSharpFluid_withWebp_tracedSVG
				}
			}
		}
	}
`;

export default SuccessPage;
